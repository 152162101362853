import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Live from '@/views/live'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/live',
    name: 'Live',
    component: Live,
    meta:{
      title: '大会直播'
    }
  },
  {
    path: '/404',
    name: 'notFound',
    component: () => import( '@/components/404notFound'),
    meta:{
      title: '出错了~'
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
