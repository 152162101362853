


function countDown(timestamp,format) {
    function checkTime(i){
        //将0-9的数字前面加上0，例1变为01
        if (i<10) {
            i = "0"+i;
        }
        return i;
    }
        let output = null;
            let date = new Date();
            let now = date.getTime();
            //设置截止时间
            let endDate = new Date(timestamp);
            let end = endDate.getTime();
            //时间差
            let leftTime = end-now;
            //定义变量 d,h,m,s保存倒计时的时间
            if (leftTime>=0) {
                if(format==='dhms'){
                    output = {
                        d:'',
                        h:'',
                        m:'',
                        s:''
                    };
                    output.d = Math.floor(leftTime/1000/60/60/24);
                    output.h = Math.floor(leftTime/(1000*60*60)%24);
                    output.m = Math.floor(leftTime/1000/60%60);
                    output.s = Math.floor(leftTime/1000%60);

                }else if(format==='hms'){
                    output = {
                        h:'',
                        m:'',
                        s:''
                    };
                    // output.d = Math.floor(leftTime/1000/60/60/24);
                    output.h = Math.floor(leftTime/1000/60/60);
                    output.m = Math.floor(leftTime/1000/60%60);
                    output.s = Math.floor(leftTime/1000%60);
                }else if(format==='ms'){
                    output = {
                        m:'',
                        s:''
                    };
                    // d = Math.floor(leftTime/1000/60/60/24);
                    // h = Math.floor(leftTime/1000/60/60);
                    output.m = Math.floor(leftTime/1000/60);
                    output.s = Math.floor(leftTime/1000%60);
                }else {
                    console.log("仅支持分秒、分秒小时、分秒小时天");
                }
                for(let key in output){
                    output[key] = checkTime(output[key])
                }

            }else {
                console.log("当前时间大于输入时间");
            }

            return output



}





export default countDown;