import {get, post, post_complex, put} from './base'

//直播会议详情
/**
 * 获取直播详情，传入直播id
 * @param id
 * @returns {Promise<unknown>}
 */
export function getDetail(id,member_id){
    return  post('/api2/v1/app/live-meet/detail',{
        'live_meet_id':id,'member_id':member_id
    })
}

/**
 * 登录
 * @param phone 手机号
 * @param code 验证码
 * @returns {Promise<* | void>}
 * {
	"message": "OK",
	"code": 0,
	"status_code": 200,
	"data": {
		"id": "FF25F268-3495-4651-9375-083CDE6A229E",
		"name": "邱",
		"tel": "15169160632",
		"is_register": 1,
		"token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IkZGMjVGMjY4LTM0OTUtNDY1MS05Mzc1LTA4M0NERTZBMjI5RSIsIm5hbWUiOiLpgrEiLCJ0ZWwiOiIxNTE2OTE2MDYzMiIsImlzX3JlZ2lzdGVyIjoxfQ.6ojGm84OC7s6yWAw4LIJG0BjMVh65fdOC8t6sZOOs40"
	}
 */
export function login(phone,code){
    return post('/api/v1/app/common/live-h5-login',{
        "phone": phone,
        "code": code
    })
}

/**
 * 发送短信验证码
 * @param phone 手机号
 * @returns {Promise<* | void>}
 * {
	"message": "OK",
	"code": 0,
	"status_code": 200,
	"data": {
		"message": "发送成功！",
		"code": "471631",
		"code_intro": "code 字段为临时测试使用，后面对接短信服务后通过手机短信收取验证码，code 字段将会移除。"
	}
}
 */
export function send_sms_code(phone){
    return post('/api/v1/app/common/send-sms-code',{
        "phone": phone,
        "type": "LOGIN"
    })
}


/**
 * 检查直播是否登录或缴费
 * @param conventionId
 * @param memberId
 * @returns {Promise<unknown>}
 */
export function check_login_pay(convention_id,member_id){
    return post('/api2/v1/app/live-meet/check_login_pay',{
        "conventionId":convention_id, member_id
    })
}


/**
 * "stream_id": 21,
 "user_id": "72C7F45E-93BE-41CD-88F7-CB31E498D95A",
 "type": "LIVE",
 "start_time": "2021-06-24 12:00:00",
 "end_time": "2021-06-25 08:30:43"
 添加直播会议观看记录
 * @param stream_id 直播会议ID
 * @param user_id 	用户的ID
 * @param type 直播类型LIVE：直播， VOD：点播
 * @param start_time 开始时间
 * @param end_time 	结束时间
 * @returns {Promise<* | void>}
 */
export function add_watch_record(stream_id,user_id,type,start_time,end_time,convention_id){
    return post('/api2/v1/app/live-meet/add_watch_record',{
        stream_id,user_id,type,start_time,end_time,convention_id
    })
}

/**
 * 注册
 * @param phone
 * @param name
 * @param hospital_name
 * @param hospital_offices
 * @param job_title
 * @param password
 * @returns {Promise<* | void>}
 */
export function register(member_id,phone,name,hospital_name,hospital_offices,job_title,password,headers){
    return post_complex('/api/v1/app/common/live-h5-register',
        {'member_id':member_id,"phone":phone,"name":name,"hospital_name":hospital_name,"hospital_offices":hospital_offices,"job_title":job_title,"password":password},
        headers
    )
}

/**
 * 获取直播协议
 * @param live_meet_id
 * @returns {Promise<* | void>}
 */
export function protocol(live_meet_id){
    return post('/api2/v1/app/live-meet/protocol', {"live_meet_id":live_meet_id})
}

/**
 *添加直播会议上报记录
 * @param stream_id 会议ID
 * @param member_id 用户id
 * @param type 观看方式
 * @param start_time 开始时间
 * @param end_time 结束时间
 * @param convention_id 大会id
 * @param duration  持续时间
 * @returns {Promise<* | void>}
 */
export function  add_reported_record(stream_id,member_id,type,convention_id,duration){
    return post('/api2/v1/app/live-meet/add_reported_record',{
        'stream_id':stream_id,
        'member_id':member_id,
        'type':type,
        // 'start_time':start_time,
        // 'end_time':end_time,
        'convention_id':convention_id,
        'duration':duration
    })
}

/**
 * 用户信息
 * @param member_id
 * @returns {Promise<* | void>}
 */
export function user_info(member_id){
    return post('/api2/v1/app/live-meet/user_info',{'member_id':member_id})
}
//
/**
 * 添加直播人员记录
 * @param stream_id 	直播会议ID
 * @param name 	姓名
 * @param hospital_name 	医院
 * @param convention_id 大会ID
 * @returns {Promise<* | void>}
 */
export function add_member_(stream_id,name,hospital_name,convention_id){
    return post('/api2/v1/app/live-meet/add_member',{stream_id,name,hospital_name,convention_id})
}

/**
 * 获取用户信息
 * @param member_id
 * @returns {Promise<unknown | void>}
 * @private
 */
export function user_info_(member_id){
    return post('/api2/v1/app/live-meet/user_info',{'member_id':member_id})
}



