import DPlayer from 'dplayer';

export async function player(live_data){
    //live_status 直播状态 NOT_STARTED：未开始   IN_HAND:进行中   ENDED：已结束
    //这里只需要管理进行中以及已结束
    if (live_data.live_status==='IN_HAND'){
        //进行中
        try {
            const dp=await new DPlayer({
                container: document.getElementById('dplayer'),
                video: {
                    url: live_data.pull_m3u8_ip,
                    type: 'hls',
                    pic:live_data.cover_url,
                },
                live:true,
                autoplay:true,
            });
            return dp;
        }catch (e) {
            return -1;
        }
    }else if (live_data.live_status==='ENDED'){
        try {
            //已结束
            const dp = await new DPlayer({
                container: document.getElementById('dplayer'),
                video: {
                    url:live_data.video_data[0].video_url,
                    pic:live_data.cover_url
                },
            });
            return dp;
        }catch (e) {
            return -1
        }
    }else {
        return -1;
    }

}

