<template>
  <div class="live"
       v-loading.fullscreen.lock=loading
       element-loading-background="#F4F5F8">
    <!--    <el-alert-->
    <!--        center-->
    <!--        close-text="知道了"-->
    <!--        effect="dark"-->
    <!--        title="为保证计时的准确性，请使用最新版Chrome浏览器观看"-->
    <!--        type="error">-->
    <!--    </el-alert>-->
    <!--    <el-button @click="test1">131</el-button>-->
    <!-- 顶部    -->
    <el-row  class="NavHeader">
      <el-col :span="5" :offset="18" class="header_info">
        {{ chat_list_input_disable?'当前身份为游客，请登录后再发言.':user_info.name }}
        <el-button @click="exitLogin" size="mini">
          {{ chat_list_input_disable?'登录':'退出登录' }}
        </el-button>
      </el-col>
    </el-row>



    <!--  顶部菜单栏  -->
    <el-row :gutter="20" class="secondary_Header">
      <el-col :offset="1" :span="17" class="phone_watch">
        <span>
          <el-button round><i class="el-icon-mobile-phone">&nbsp;</i>手机观看</el-button>
          <div class="watch_qr" ref="qrCodeUrl"></div>
        </span>
      </el-col>
<!--        报名信息 -->
      <el-col :span="5" class="duration_info"
                v-show="duration_info_display">
<!--        显示内容-->
        <div class="container"
            @mouseenter="secondary_Header_fold_container_show=true"
            @mouseleave="secondary_Header_fold_container_show=false">
          <div class="reveal_container">
            当前登录账号：<br>
            {{login_info.name}} {{ login_info.tel }}   {{login_info.hospital_name}}<br>
            未上报时长：{{getHMS(not_report_duration)}}
            <el-button class="report_button" size="mini" type="danger" @click="get_add_reported_record">上&nbsp;报</el-button>
          </div>
  <!--        折叠内容-->
          <transition name="el-zoom-in-top"
                      @mouseenter="secondary_Header_fold_container_show=true">
            <div v-show="secondary_Header_fold_container_show" class="fold_container">
              <div class="add_up_duration">
                累计观看时长：{{getHMS(duration_info.duration)}}
              </div>
              <el-divider></el-divider>
              <div class="apply_info">
                学分报名状态：<span :style="{color:duration_info.credit_user_status===true?'#11E71BFF':'red'}">{{duration_info.credit_user_status===true?'已报名':'未报名'}}</span><br>
                {{ credit_user_info.name }} {{ credit_user_info.tel }}  {{credit_user_info.hospital_name}}
              </div>
              <el-divider></el-divider>
              <div class="notes" >
                {{live_data.duration_remark}}
              </div>
            </div>
          </transition>
        </div>
      </el-col>
    </el-row>


    <el-row :gutter="20" class="live_controller" >
      <!--视频-->
      <el-col :offset="1" :span="17" :style="{height:live_controller_height}" >
        <div class="live_controller_player">
          <!--封面图-->
          <div v-if="live_data_NOT_STARTED" class="cover_url" ref="player" :style="{height:player_height,backgroundImage:'url(' + live_data.cover_url + ')'}">
              <!--  展示倒计时  -->
            <div class="time_countdown" v-if=timeCountdown_show>
              距离开始还有:
              {{TimeCount.d}}天
              {{TimeCount.h}}小时
              {{TimeCount.m}}分
              {{TimeCount.s}}秒
            </div>
          </div>
          <!--视频-->
<!--          <div v-if="live_data_NOT_STARTED===false" id="dplayer" ref="player" :style='{height:player_height}'></div>-->
          <div v-if="live_data_NOT_STARTED===false" id="dplayer" ref="player"></div>
          <!-- 视频标题       -->
          <div class="live_info">
            <!--          主要内容 ，包括标题， 人数分享-->
            <div class="live_info_main">
              <div class="controller">
                <div class="title">
                  <span>{{live_data.title}}</span>
                </div>
                <div class="live_watch_number">
                  <span>
                    <i class="el-icon-view"></i>
                    {{ online_number }}
                    <i class="el-icon-share"></i>
                    分享
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>

      <!--            互动 -->
      <el-col :span="5" class="live_controller_interplay" :style="{height:live_controller_height}">
        <div class="container">
          <!--   Menu 导航栏       -->
<!--          <el-menu :default-active="activeIndex" mode="horizontal" class="interplay_menu" @select="handleSelect">-->
<!--            <el-menu-item index="1">讨论</el-menu-item>-->
<!--            <el-menu-item index="2">回看</el-menu-item>-->
<!--          </el-menu>-->
          <div class="interplay_menu">
            <div @click="alter_interplay_index_1" id="menu_text_1" class="menu_text">
              讨论
            </div>
            <el-divider direction="vertical"></el-divider>
            <div @click="alter_interplay_index_2" id="menu_text_2" class="menu_text">
              回看
            </div>
          </div>
          <el-divider></el-divider>


          <!--    聊天框      -->
          <div class="chat_list" :style="{height:chat_list_height}" v-show="interplay_index===1">
            <div class="container" v-for="(chat,index) in chat_list" :key="index">

              <div class="left_main" v-if="chat.user_id !== userId">
                <!--              头像-->
                <el-avatar size="medium" icon="el-icon-user-solid"></el-avatar>
                  <!--  聊天框 -->
                <div class="information">
                  <div class="message_name">{{chat.name}}</div>
                  <el-card shadow="never">
                    {{chat.message}}
                  </el-card>
                </div>
              </div>
                        <!--     自己的聊天框         -->
              <div class="right_main" v-if="chat.user_id === userId">
                <!--  聊天框 -->
                <div class="information">
                  <div class="message_name">{{chat.name}}</div>
                  <el-card shadow="never">
                    {{chat.message}}
                  </el-card>
                </div>
                <el-avatar  size="medium" icon="el-icon-user-solid"></el-avatar>
              </div>
            </div>
          </div>
          <!--   输入框       -->
          <el-input
              v-show="interplay_index===1"
              size="small" v-model="message"
              :autofocus=true :placeholder="chat_list_input_message"
              @keyup.enter.native="getMessage"
              :disabled="chat_list_input_disable">
            <el-button slot="append"  @click="chat_list_input_disable?exitLogin():getMessage()"> {{chat_list_input_disable?'登录':'发送'}}</el-button>
          </el-input>
          <!--  回看        -->
          <div class="look_back" v-show="interplay_index===2">
            <div class="container">
              <div class="video_list"
                   v-for="(video,index) in live_data.video_data"
                   :key="video.id">
                <el-button type="text" :id='"video_list"+index'  @click="switchVideo(video.video_url,index)">
                  <i class="el-icon-video-play"></i>
                  第{{index+1}}集
                </el-button>
                <el-divider></el-divider>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>



    <el-row :gutter="20">
      <el-col :offset="1" :span="17" >
        <div class="live_more">
          <div class="menu">
            <span class="name">
              <i class="el-icon-date"></i>
              会议议程
            </span>
          </div>
          <div class="line">
            <div class="controller"></div>
          </div>
          <div class="poster">
            <img :src=live_data.desc_image_url>
          </div>
        </div>
      </el-col>
    </el-row>


    <div class="live_foot"></div>


<!--    登录对话框-->
    <el-dialog
        class="login_dialog"
        title="登录"
        :visible.sync="login_disable"
        width="30%"
        center
        :show-close=false
        :close-on-click-modal=false
        :close-on-press-escape=false
        :modal=true
        style="background-color:#1BA4DE">
      <div class="container">
        <el-input class="phone" v-model="phone_number" placeholder="请输入手机号">
          <el-button slot="append" @click="send_sms_code" :disabled=send_sms_code_button>获取验证码</el-button>
        </el-input>
        <el-input class="code" v-model="code" placeholder="请输入验证码" @keyup.enter.native="login"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="login">登 录</el-button>
        <p class="visitor_login" @click="visitor_login">游客登录</p>
      </span>
    </el-dialog>


<!--    完善信息对话框-->
    <el-dialog
        :visible.sync="register_disable"
        width="40%"
        title="注册"
        :show-close=false
        :close-on-click-modal=false
        :close-on-press-escape=false
        center>

      <el-form label-width="auto">
        <el-form-item label="手机号:">
          <el-input :disabled="true" v-model="register.phone"></el-input>
        </el-form-item>
        <el-form-item label="姓名:">
          <el-input v-model="register.name"></el-input>
        </el-form-item>
        <el-form-item label="医院名称:">
          <el-input v-model="register.hospital_name"></el-input>
        </el-form-item>
        <el-form-item label="科室:">
          <el-input v-model="register.hospital_offices"></el-input>
        </el-form-item>
        <el-form-item label="职称:">
          <el-input v-model="register.job_title"></el-input>
        </el-form-item>
        <el-form-item label="密码:">
          <el-input placeholder="最少为6位"  show-password v-model="register.password"></el-input>
        </el-form-item>

        <el-form-item size="large">
          <el-button type="primary" @click="getRegister">立即注册</el-button>
          <el-button @click="register_disable=false">取消</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>

<!--    用户协议对话框-->
    <el-dialog
        :visible.sync='protocol_disable'
        title="用户协议"
        :show-close=false
        :close-on-click-modal=false
        :close-on-press-escape=false
        style="background-color:#1BA4DE"
        center>
      <p v-html="protocol_response.protocol_content"></p>
      <span slot="footer" class="dialog-footer">
        <el-button @click='close_window'>不同意并退出</el-button>
        <el-button type="primary" @click="submit_agree_protocol">同意并继续</el-button>
      </span>
    </el-dialog>


<!--    登录类型为医院姓名，不是手机号验证码了-->
    <el-dialog
      :visible.sync="name_hospital_disable"
      center
      width="500px"
      :show-close=false
      :close-on-click-modal=false
      :close-on-press-escape=false
      style="background-color:#1BA4DE"
      title="信息登记"
    >
      <div class="container">
        <el-input v-model="add_member_name"   placeholder="请输入姓名"></el-input>
        <el-input v-model="add_hospital_name"   placeholder="请输入医院"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="add_member">完成输入并进入直播间</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {player} from '@/components/player'
import {
  add_member_,
  add_reported_record,
  add_watch_record,
  check_login_pay,
  getDetail,
  login,
  protocol,
  register,
  send_sms_code,
  user_info_
} from '@/service/api'
import {Message} from "element-ui";
import QRCode from 'qrcodejs2'
import VueSocketIO from 'vue-socket.io'
import Vue from 'vue'
import countDown from "@/utils/mycountDown";

export default {
  data(){
    return{
      //live_id
      room:'',
      //播放器高度
      player_height:'',
      //播放器父容器高度以及右边互动栏的高度
      live_controller_height:'',
      //聊天室高度
      chat_list_height:'',
      //顶部图片地址
      top_img:'https://live-web-pc-1252497858.cos.ap-guangzhou.myqcloud.com/live_pc%E9%A1%B6%E9%83%A8.png',
      //直播详情
      live_data:[],
      //是否只展示封面图
      live_data_NOT_STARTED:false,
      //播放器
      dp:'',
      //加载
      loading:true,
      //socketId
      socketId:'',
      //在线人数
      online_number:'',
      //聊天记录
      chat_list:[],
      //输入框内容
      message:'',
      //userId
      userId:'',
      //是否需要登录,显示登录弹窗，true为显示，false为隐藏
      login_disable:false,
      //手机号
      phone_number:'',
      //验证码
      code:'',
      //发送验证码按钮，是否可以点击
      send_sms_code_button:false,
      //观看开始时间
      start_time:'',
      //观看结束时间
      end_time:'',
      //是否展示倒计时
      timeCountdown_show:false,
      //倒计时存储数字
      TimeCount:{},
      //倒计时所用到的计时器,最后作为消除所用
      int:null,
      //注册的对话框
      register_disable:false,
      //注册的信息
      register:{
        phone:'',
        name:'',
        hospital_name:'',
        hospital_offices:'',
        job_title:'',
        password:'',
      },
      conventionId:'',
      live_end_time:'',
      //自动添加时长计时器
      int_addWatch:null,
      //互动框已经选择的
      interplay_index:1,
      //互动区视频切换保存上一个已经选择的视频
      old_video_index:0,
      //顶部菜单栏报名信息是否显示更多的内容
      secondary_Header_fold_container_show:false,
      //时长信息(包括 登录者信息，报名的信息)
      duration_info:'',
      //登录者信息
      login_info:'',
      //报名的信息
      credit_user_info:'',
      //是否显示时长的栏目
      duration_info_display:false,
      //未上报时长
      not_report_duration:'',
      //协议内容
      protocol_response:'',
      //是否显示协议弹窗
      protocol_disable:false,
      //协议是否已经确认过了
      agree_protocol:false,
      //登录状态为姓名医院的对话框
      name_hospital_disable:false,
      //添加人员姓名
      add_member_name:'',
      //添加人员医院
      add_hospital_name:'',
      //是否通过身份验证
      verify_identity:false,
      //获取道德用户信息
      user_info:'',
      //不允许发言的提示
      chat_list_input_message:'请输入内容~',
      //输入框状态
      chat_list_input_disable:false
    }
  },
  async beforeRouteEnter (to, from, next) {
    //获取live_id/room
    const room = to.query.room
    if (room!==null&&room!==""&&room!==undefined){
      //房间号不为空
      //首先在对象存储中尝试获取user_id，如果没有则提示登录
      //在这里判断获取数据是弹出手机号还是姓名医院

      //1.获取本地用户数据，
        //有：
        //没有:
      let user_id =  localStorage.getItem('user_id')
      let token = localStorage.getItem('user_token')
      let member_id;
      if (user_id !== null &&user_id !=='' && user_id !== undefined){
        member_id = user_id
      }else {
        member_id = null
      }

      //2.全局变量获取直播详情
      const live_data = await getDetail(parseInt(room), member_id)
      //3.判断登录方式是手机号还是姓名医院

      //获取本地存储中是否有姓名医院了
      const add_member_name_hospital = JSON.parse(localStorage.getItem('add_member_name_hospital_'+room))
      const login_type = live_data.login_type
      if (login_type==='phone_code'){
        //判断是否通过验证
        if (user_id !== null && user_id !== "" && user_id !== undefined &&
            token !== null &&token !== "" && token !== undefined)
        {
          //继续下一步赋值
          // next(vm => {
          //   vm.verify_identity = true
          // })
        }else {
          //打开验证码登录的弹窗
          next(vm => {
            vm.login_disable = true
            vm.verify_identity = false
          })
        }
      }else if(login_type==='name_hospital'){
        //判断是否通过验证
        //判断是否已经登录了，如果登录了，就不需要弹姓名医院的弹窗了，如果是游客依然要弹窗
        if (user_id !== null && user_id !== "" && user_id !== undefined
            &&user_id!=='78ce3cd0-e42b-11ea-8c91-23f1f183e1f9')
        {
          //继续下一步赋值
          // next(vm => {
          //   vm.verify_identity = true
          // })
        }else {
          //如果没有登录，但是已经填写过，也不需要再次填写了
          if(add_member_name_hospital !== null
          && add_member_name_hospital !== ""
          && add_member_name_hospital !== undefined){

          }else {
            //打开姓名医院的弹窗
            next(vm => {
              vm.verify_identity = false
              vm.name_hospital_disable = true
              //赋值获取到的会议信息，以用于提交姓名医院
              //房间id
              vm.room=room
              //大会id
              vm.conventionId = live_data.convention_id
            })
          }
        }
      }

      //判断是否是大会直播，判断权限问题
      //全局变量，获取详情中的大会id
      let conventionId =  live_data.convention_id
      //判断url中是否有conventionId
      //当有缴费会议的时候
      //判断url地址中是否有缴费会议
      if (conventionId!==null&&conventionId!==''&&conventionId!==undefined){
        //url中存在conventionId
        //需要验证是否可以观看
        //转型int
        conventionId = parseInt(conventionId)
        //发送请求
        const response =  await check_login_pay(conventionId,user_id)
        console.log(response)
        if (response.code ===0){
          //接口正确
          if (response.data.is_need_pay===true){
            //需要缴费，则跳转到错误界面
            next(vm => {
              vm.$router.push('/404')
            });
          }
        }else {
          //接口错误，直接跳到错误界面
          next(vm => {
            vm.$router.push('/404')
          });
        }
      }

      //如果conventionId为null 则不进行验证，直接下一步
      //下一步获取协议并验证是否同意协议
      //加载数据前判断是否有协议需要填写
      const response_protocol =  await protocol(parseInt(room) )
      let protocol_disable = true
      if (response_protocol.code===0) {
        //判断是否有内容，有内容才会弹窗
        if (response_protocol.data.protocol_content !== ''
            && response_protocol.data.protocol_content !== undefined) {
          //有内容需要弹窗
          //在对象存储中判断是否已经提交过了，没有提交的才会继续弹窗
          /**
           *  const protocol_localStorage={
            'live_id':room,
            'is_agree_protocol':1
            }
           */
          //获取本地存储,判断是否需要再填写问卷
          const get_protocol_localStorage =JSON.parse(window.localStorage.getItem('is_protocol_localStorage_'+room))
          if (get_protocol_localStorage!==null&&get_protocol_localStorage.live_id!==undefined&&get_protocol_localStorage.live_id===room) {
            //已经确认过问卷，不需要弹窗,直接下一步
            protocol_disable= false
            // next(vm => {
            //   vm.protocol_response = response_protocol.data
            //   vm.protocol_disable=false
            // })
          } else {
            //没有确认过,则需要弹窗
            protocol_disable= true
            next(vm => {
              vm.protocol_response = response_protocol.data
              vm.protocol_disable=true
            })
          }
        } else {
          //没有内容，不需要弹窗,直接下一步

        }
      }else {
        //获取错误，则需要跳转到404
        next(vm => {
          vm.$router.push('/404')
        });
      }

      //加载VueSocketIO
      let params = {
        room:room ,  // 当前会议直播的ID
        token:token,
        user_id:user_id,// 当前登录用户ID
        x_client:'live_pc'
      }
      await Vue.use(new VueSocketIO({
        debug: false,
        // 连接地址 后端提供
        connection: 'wss://yunicu-api.yunicu.com',
        options: {
          query: params,
          transports: ['websocket'] }
      }))
      next(vm =>{
        //赋值数据，页面正常进行
        //房间id
        vm.room=room
        //用户id
        vm.userId=user_id
        //直播详情数据
        vm.live_data = live_data
        //大会id
        vm.conventionId = conventionId
        //是否可以渲染数据
        vm.verify_identity = true
      });
    }else {
      //房间号为空，则跳转到404
      next(vm => {
        vm.$router.push('/404')
      });
    }
  },
  sockets:{
    // 链接成功
    connect(data) {
      this.socketId = data;
      // console.log("服务器链接"+data)
    },
    //重新链接
    reconnect(data){
      console.log("重新链接",data)
      this.$socket.emit('connect',1)
    },
    // 断开链接回调
    disconnect() {
      console.log('disconnect：', '已经断开 socket 链接')
    },
    //服务端指定有msg监听的客服端，可接对应发来的收消息，data服务端传的消息
    online(data){
      let number = JSON.parse(data)
      this.online_number = number.count
    },
    //获取聊天记录
    chat_list(data){
      let list = JSON.parse(data)
      list  = list.list
      this.chat_list = list
    },
    //接受消息,有人发送消息，这里就会执行
    add_record(Jsondata){
      let data = JSON.parse(Jsondata)
      this.chat_list.push({
        'message':data.message,
        'name':data.name,
        'user_id':data.user_id,
      })
    },
    //socket返回的数据如果is_need_login 是true就让他登录去
    login_info(data){
      console.log('login_info='+data);
    },
    /// 错误信息 如果后台服务报错，会以’error’事件通知，用于排查错误原因
    error(err) {
      console.log('error', err);
    }
  },
  async beforeCreate() {

  },
  async created() {
    //添加观看记录__获取当前时间
    this.start_time =this.getCurrentTime()
    console.log(this.start_time)
  },
  async mounted() {
    //获取之前传来的live_id
    let live_id = this.room
    //格式化live_id
    live_id = parseInt(live_id)
    //判断是手机还是pc pc继续，手机跳转到手机平台
    if (this._isMobile()){
      //判断一下conventionId是否存在
      if (this.conventionId!==''&&this.conventionId!==null&&this.conventionId!==undefined) {
        window.location.href="https://live-h5.yunicu.com/#/chat-online?room="+this.$route.query.room+"&conventionId="+parseInt(this.conventionId)
      }else {
        window.location.href="https://live-h5.yunicu.com/#/chat-online?room="+this.$route.query.room
      }
    }else {
      //加载数据
      const live_data = this.live_data
      //判断是否已经登录过了需要登录
      if (this.verify_identity) {
        //是否对页面进行渲染

        //获取用户信息
        const user_info = await user_info_(this.userId)
        this.user_info = user_info.data

        //设置页面标题
        document.title = live_data.title
        //获取直播结束时间
        this.live_end_time = live_data.end_time
        //duration_info 时长信息，后台未开启，没有该字段
        if (live_data.duration_is_open === 1) {
          if (live_data.duration_info.login_status===true){
            //表示有时长已开启
            //显示观看时长的栏目表
            this.duration_info_display = true
            //赋值
            this.duration_info = live_data.duration_info
            this.login_info = live_data.duration_info.login_info
            if(live_data.duration_info.credit_user_info!==undefined){
              this.credit_user_info = live_data.duration_info.credit_user_info
            }
            //如果会议已经开始则开始计时
            if(live_data.live_status !== 'NOT_STARTED'){
              this.record_not_report_duration()
            }
          }
        }
        //判断是否是游客，允许发言
        this.is_visitor()
        //加载二维码
        if (this.conventionId !== '' && this.conventionId !== null && this.conventionId !== undefined) {
          this.creatQrCode("https://live-h5.yunicu.com/#/chat-online?room=" + live_id + "&conventionId=" + parseInt(this.conventionId))
        } else {
          this.creatQrCode("https://live-h5.yunicu.com/#/chat-online?room=" + live_id)
        }
        //判断直播状态，是否加载视频
        //判断是直播状态，如果是未开始就没必要加载视频
        if (live_data.live_status === 'NOT_STARTED') {
          //展示kv图片
          this.live_data_NOT_STARTED = true
          //加载倒计时
          //展示
          this.timeCountdown_show = true
          let timeData = new Date(live_data.start_time);
          //倒计时计时器
          this.int = setInterval(() => {
            this.TimeCount = countDown(timeData, 'dhms');
          }, 1000);

        } else {
          //如果直播不是未开始，就需要加载播放器
          const dp = await player(live_data)
          //取消dp播放器右键
          document.querySelector("#dplayer").oncontextmenu = () => {
            document.querySelector(".dplayer-menu").style.display = "none";
            document.querySelector(".dplayer-mask").style.display = "none";
            return false;
          };
          //如果播放器返回参数为错误，则加载图片
          if (dp === -1) {
            this.live_data_NOT_STARTED = true
          } else {
            this.dp = dp;
            this.live_data_NOT_STARTED = false
          }
        }
      }
    }
    //动态改变高度
    this.getDynamicHeight()
    //保持滚动条在最底层
    this.scroll_bar_bottom()
    //监听浏览器大小，动态改变高度
    await this.$nextTick(() => {
      window.addEventListener('resize', () => { //监听浏览器窗口大小改变
        //浏览器变化执行动作
        this.getDynamicHeight()
      });
    })
    //取消加载_显示界面
    this.loading=false

    //监听关闭
    // window.addEventListener('beforeunload', async e =>await this.beforeunloadHandler(e))

    // window.onbeforeunload= (e)=>{
    //   //如果未登录，则不加载关闭提示
    //   if (this.verify_identity){
    //     e = e || window.event;
    //     if (e) {
    //       e.returnValue = '关闭提示';
    //     }
    //     this.add_watch()//调用自己的方法
    //     // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
    //     return '关闭提示';
    //   }
    // };

    //配置计时器，每过15分钟自动提交一次时间
     this.int_addWatch =  setInterval(async ()=>{
         await this.add_watch()
    },900000);

  },
  updated() {
    //更新保持滚动条在最底层
    this.scroll_bar_bottom()
  },
  async beforeDestroy() {
    //结束前,断开sockets链接
    this.$socket.emit('disconnect',this.socketId)
    //关闭倒计时计时器，将数据设为空
    clearInterval(this.int);
    clearInterval(this.int_addWatch);
    this.TimeCount = null;
    clearInterval(this.not_report_duration_int);
    this.not_report_duration=null


  },
  methods:{
    //通过获取宽度改变播放器高度，全局响应式
    getDynamicHeight() {
      const player_width = this.$refs.player.offsetWidth;
      this.player_height = (player_width * 9) / 16 + "px"
      this.live_controller_height = (player_width * 9) / 16 + 70 + "px"
      // 聊天列表高度
      this.chat_list_height = (player_width * 9) / 16 + 120 - 150+"px"
    },
    //切换视频
    switchVideo(video_url,index){
      this.dp.switchVideo(
          {
            url: video_url,
            pic: this.live_data.cover_url
          },
      );
      window.document.getElementById("video_list"+index).style.color='#1BA4DE'
      window.document.getElementById("video_list"+this.old_video_index).style.color='#393939'
      this.old_video_index = index
    },
    //判断是否是手机
    _isMobile() {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    },
    //加载二维码
    creatQrCode(url) {
      var qrcode =new QRCode(this.$refs.qrCodeUrl, {
        text:url,// 需要转换为二维码的内容
        width: 90,
        height: 90,
        colorDark:'#000000',
        colorLight:'#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    //保持滚动条在最底部
    scroll_bar_bottom(){
      this.$nextTick(()=>{
        let box = this.$el.querySelector(".chat_list")
        box.scrollTop = box.scrollHeight
      })
    },
    //发送消息
    getMessage(){
      let message = this.message
      message =  message.replace(/(^\s*)|(\s*$)/g, "");
      //非空判断
      if (message!==""){
        //判断是否注册了，只有注册过才能发言,没有注册则弹出注册框
        //获取本地存储中的数据并将json转为对象
        const user_info = JSON.parse(localStorage.getItem('user_info'))
        if (user_info === null){
          this.$socket.emit('add_message', JSON.stringify({ message: message}));
          Message.success("发送成功~")
          this.message=""
        }else {
          //判断是否有姓名，is_register是否注册：0：否 1：是
          if (user_info.name!==''&&user_info.name!==null&&user_info.name!==undefined&&user_info.is_register===1){
            //已经注册
            this.$socket.emit('add_message', JSON.stringify({ message: message}));
            Message.success("发送成功~")
            this.message=""
          }else {
            //没有注册过，弹出注册
            this.register_disable=true
            //存储手机号
            this.register.phone=user_info.tel
          }
        }


      }else {
        Message.error("请输入内容后在发送~")
      }
    },

    //注册
    async getRegister(){
      //非空判断
      const registerData = this.register
      //去除多余空格
      const phone=registerData.phone.replace(/(^\s*)|(\s*$)/g, "");
      const name=registerData.name.replace(/(^\s*)|(\s*$)/g, "");
      const hospital_name=registerData.hospital_name.replace(/(^\s*)|(\s*$)/g, "");
      const hospital_offices=registerData.hospital_offices.replace(/(^\s*)|(\s*$)/g, "");
      const job_title=registerData.job_title.replace(/(^\s*)|(\s*$)/g, "");
      const password=registerData.password.replace(/(^\s*)|(\s*$)/g, "");
      if (phone !== ""
          && name !== ""
          && hospital_name !== ""
          && hospital_offices !== ""
          && job_title !== ""
          && password!==""){
        //请求头
        const headers={
          'x-client-tag':'live_pc',
          'x-token': localStorage.getItem('user_token')
        }
        const  response =  await register(localStorage.getItem('user_id'),phone,name,hospital_name,hospital_offices,job_title,password,headers)
        if (response.code===0){

          //替换本地存储
          const localInfoData={
            'name':name,
            'tel':phone,
            'is_register':1,
          }
          //将要存储的数据转换为json
          localStorage.setItem('user_info',JSON.stringify(localInfoData))
          //收回对话框
          this.register_disable=false
          Message.success("注册成功~")
        }else {
          Message.error("注册失败，请稍后再试~"+response.message)
        }
      }else {
        Message.error("请完整输入~")
      }
    },


    //发送短信验证码
    async send_sms_code(){
      //非空判断
      let phone_number = this.phone_number
      phone_number=phone_number.replace(/(^\s*)|(\s*$)/g, "");
      if (phone_number !== ""){
        const response =  await send_sms_code(phone_number)
        if (response.code===0){
          Message.success("发送成功~")
          //将按钮切换为不可以点击的状态
          this.send_sms_code_button = true
        }else {
          Message.error("手机号输入错误~")
        }
      }else {
        Message.error("请您正确输入~")
      }
    },
    //登录
    async login(){
      //全局变量
      let phone_number = this.phone_number
      let code = this.code
      //取消空格
      phone_number=phone_number.replace(/(^\s*)|(\s*$)/g, "");
      code=code.replace(/(^\s*)|(\s*$)/g, "");
      //非空判断
      if (phone_number!==""&&code!==""){
       const response =  await login(phone_number,code)
        if (response.code===0){
          Message.success("登录成功~")
          //本地存储user_id
          localStorage.setItem('user_id',response.data.id)
          //本地存储token
          localStorage.setItem('user_token',response.data.token)
          // this.login_disable = false
          //本地存储tel,name，is_register用于判断是否注册使用
          const localInfoData={
            'name':response.data.name,
            'tel':response.data.tel,
            'is_register':response.data.is_register,
          }
          //将要存储的数据转换为json
          localStorage.setItem('user_info',JSON.stringify(localInfoData))
          //刷新页面
          window.location.reload();
        }else {
          Message.success("登录失败，请重试。"+response.message)
        }
      }else {
        Message.error("请您正确输入~")
      }
    },
    //获取当前时间并打印
    getCurrentTime() {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth()<9 ? '0'+parseInt(new Date().getMonth()+1) : parseInt(new Date().getMonth()+1);
      let dd = new Date().getDate()<10 ? '0'+new Date().getDate() : new Date().getDate();
      let hh = new Date().getHours()<10 ? '0'+new Date().getHours() : new Date().getHours();
      let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
      return  yy+'-'+mm+'-'+dd+' '+hh+':'+mf+':'+ss;
    },

    //游客身份登录
    visitor_login(){
      //直接存储游客本地存储
      localStorage.setItem('user_id','78ce3cd0-e42b-11ea-8c91-23f1f183e1f9')
      localStorage.removeItem('user_info')
      //添加有了user_token
      localStorage.setItem('user_token','eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Ijc4Y2UzY2QwLWU0MmItMTFlYS04YzkxLTIzZjFmMTgzZTFmOSIsIm5hbWUiOiLmuLjlrqIifQ.QXrFNargwtLbufHzBRqo-BY9IPQ-d5LA9nvkat4WucI')
      //刷新页面
      window.location.reload();
    },

    /**
     * 浏览器关闭前执行
     * [beforeunloadHandler 浏览器关闭时进行用户提示]
     *
     */
    async beforeunloadHandler(e){
      await this.add_watch()
    },
    /**
     * 自动添加会议观看记录
     * @returns {Promise<void>}
     */
     async add_watch(){
      //重新获取详情，获取现在的直播状态
      const responseDetail =  await getDetail(parseInt(this.room), this.userId)
      //结束前添加直播会议观看记录
      if (responseDetail.live_status === 'NOT_STARTED'){
        //如果会议未开始，则不添加记录
      }else {
        //如果会议为已结束，则添加视频记录，直播中则添加直播的记录
        let type =responseDetail.live_status === 'IN_HAND' ? "LIVE":"VOD"
        //获取url中的conventionId，判断是否需要传递这个参数
        // this.conventionId=this.$route.query.conventionId
        if (this.conventionId!==''&&this.conventionId!==null&&this.conventionId!==undefined){

          await add_watch_record(parseInt(this.room),this.userId,type,this.start_time, this.getCurrentTime(),parseInt(this.conventionId))
        }else {
          await add_watch_record(parseInt(this.room),this.userId,type,this.start_time, this.getCurrentTime())
        }
        //发送成功将时间置换为现在的时间
        this.start_time = this.getCurrentTime()
      }
    },

    //退出登录
    exitLogin(){
       // localStorage.removeItem('user_id')
      localStorage.removeItem('info')
      //添加游客user_id
      localStorage.setItem('user_id','78ce3cd0-e42b-11ea-8c91-23f1f183e1f9')
      //添加有了user_token
      localStorage.setItem('user_token','eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Ijc4Y2UzY2QwLWU0MmItMTFlYS04YzkxLTIzZjFmMTgzZTFmOSIsIm5hbWUiOiLmuLjlrqIifQ.QXrFNargwtLbufHzBRqo-BY9IPQ-d5LA9nvkat4WucI')
      this.login_disable = true
      //将是否登录设置为true
      // this.login_disable=true
    },

    //点击互动上方的切换栏对应的方法
    alter_interplay_index_1(){
      let interplay_index =  this.interplay_index
      if (interplay_index===2){
        interplay_index=1
      }
      this.interplay_index=interplay_index
      window.document.getElementById('menu_text_1').style.color='#03A9F4'
      window.document.getElementById('menu_text_2').style.color='#000000'
    },
    alter_interplay_index_2(){
      let interplay_index =  this.interplay_index
      if (interplay_index===1){
        interplay_index=2
      }
      this.interplay_index=interplay_index
      window.document.getElementById('menu_text_2').style.color='#03A9F4'
      window.document.getElementById('menu_text_1').style.color='#000000'
    },

    //手动上报时长
    async get_add_reported_record(){
      const conventionId = parseInt(this.conventionId)
      let response
      //重新获取详情，获取现在的直播状态
      const responseDetail =  await getDetail(parseInt(this.room), this.userId)
      //判断是否有大会直播
      if (conventionId!==null&&conventionId!==''&&conventionId!==undefined){
        const live_id = parseInt(this.room)
        const userId = this.userId
         //type
        if (responseDetail.live_status === 'NOT_STARTED'){
          //如果会议未开始，则不添加记录
          Message.error('会议未开始')
        }else {
          let type =responseDetail.live_status === 'IN_HAND' ? "LIVE":"VOD"
          response  =  await add_reported_record(live_id,userId,type,conventionId,this.not_report_duration)
        }

        if (response.message==='保存成功！'){
          this.not_report_duration = 0
          //刷新数据
          const  response = await getDetail(live_id,userId)
          this.duration_info = response.duration_info
          Message.success(responseDetail.live_status === 'IN_HAND' ? "上报成功，您上报的是:LIVE":"上报成功，您上报的是:VOD")
        }else {
          Message.error('上报失败'+response.Message)
        }
      }else {
        Message.error('上报失败'+response.Message)
      }
    },
    //将观看时长转换为小时分钟
    getHMS(time){
      const hour = parseInt(time / 3600) < 10 ? '0' + parseInt(time / 3600) : parseInt(time / 3600)
      const min = parseInt(time % 3600 / 60) < 10 ? '0' + parseInt(time % 3600 / 60) : parseInt(time % 3600 / 60)
      const sec = parseInt(time % 3600 % 60) < 10 ? '0' + parseInt(time % 3600 % 60) : parseInt(time % 3600 % 60)
      return hour + '小时' + min + '分钟' + sec+'秒'
    },

    //记录未上报时长
    record_not_report_duration(){
      this.not_report_duration_int = setInterval(()=>{
        this.not_report_duration = parseInt(this.not_report_duration+1)
      },1000);
    },

    //点击同意协议并继续
    submit_agree_protocol(){
      const protocol_localStorage={
        'live_id':this.$route.query.room,
        'is_agree_protocol':1
      }
      //本地存储
      window.localStorage.setItem('is_protocol_localStorage_'+this.$route.query.room,JSON.stringify(protocol_localStorage))
      //刷新页面
      window.location.reload();
    },
    //关闭网页的方法
    close_window(){
      window.open("about:blank", "_top").close()
    },
    //添加直播人员记录
    async add_member(){
       //非空判断
      if (this.add_member_name!==null
          &&this.add_member_name!==''
          &&this.add_hospital_name!==null
          &&this.add_hospital_name!=='')
      {
        let response;
        //判断是否有大会id
        if (this.conventionId!==''){
          response =  await add_member_(parseInt(this.room),this.add_member_name,this.add_hospital_name,parseInt(this.conventionId))
        }else {
          response = await add_member_(parseInt(this.room),this.add_member_name,this.add_hospital_name,null)
        }
        if (response.code===0){
          const add_member_localStorage_={
            'live_id':this.room,
            'add_member_name':this.add_member_name,
            'add_hospital_name':this.add_hospital_name,
          }
          //做本地存储
          window.localStorage.setItem('add_member_name_hospital_'+this.room,JSON.stringify(add_member_localStorage_))
          //本地存储
          //存储user_id
          localStorage.setItem('user_id','78ce3cd0-e42b-11ea-8c91-23f1f183e1f9')
          //存储token
          localStorage.setItem('user_token','eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Ijc4Y2UzY2QwLWU0MmItMTFlYS04YzkxLTIzZjFmMTgzZTFmOSIsIm5hbWUiOiLmuLjlrqIifQ.QXrFNargwtLbufHzBRqo-BY9IPQ-d5LA9nvkat4WucI')
          localStorage.removeItem('user_info')
          //刷新页面
          window.location.reload();
        }
      }else {
        Message.error('请完整输入后再继续。')
      }
    },

    //判断是否是游客，如果是游客则不允许发言
    is_visitor(){
       //本地存储获取user_id
      let user_id =  localStorage.getItem('user_id')
      if (user_id !== null &&user_id !=='' && user_id !== undefined){
        if (user_id==='78ce3cd0-e42b-11ea-8c91-23f1f183e1f9'){
          this.chat_list_input_message='您现在为游客状态，不允许发言，请登录后尝试。'
          this.chat_list_input_disable = true
          this.duration_info_display = false
        }
      }
    }


    // test1(){
    //   this.add_watch()
    // }
  },
  destroyed() {
    window.removeEventListener('onbeforeunload', e => this.beforeunloadHandler(e))
  }

}
</script>


<style lang="scss">
body{
  min-width: 1280px;
  padding: 0;
  margin: 0;
}
.live{
  background-color: rgb(251,251,253);
  overflow-y: hidden;
  .NavHeader{
    height:50px;
    width: 100%;
    background-color: #1BA4DE;
    .header_info{
      line-height: 50px;
      text-align: right;
      font-size: 13px;
      font-family: Adobe Heiti Std,serif;
      font-weight: normal;
      color: #FFFFFF;
    }
  }
  .secondary_Header{
    height: 79px;
    .phone_watch{
      line-height: 79px;
      text-align: right;
      span{
        position: relative;
        .el-button{
          border: 2px solid  #03A9F4;
        }
        &:hover{
          .watch_qr{
            display: block;
            z-index: 100;
            position: absolute;
            right: 0;
            left: 17.5px;
            top: 35px;
            width: 125px;
          }
        }
        .watch_qr{
          display: none;
        }
      }
    }
    .duration_info{
      height: 79px;
      position: relative;
      display: flex;
      align-items: center;

      .container{
        height:65px ;
        background-color: rgb(27,164,222);
        font-size: 12px;
        color: #FFFFFF;
        border-radius: 5px;
        line-height: 20px;
        width: 100%;
        .el-divider{
          margin: 3px 0 3px 0;
          padding: 0;
          background-color: rgba(9, 89, 123, 0.73);
        }
        //持续显示的内容
        .reveal_container{
          padding:0 0 0 10px;
          position: relative;
          margin-bottom: 5px;
          .report_button{
            position: absolute;
            right: 10px;
            bottom: 0;
            text-align: right;
            //height: 17px;
            //font-size: 12px;
          }
        }
        //折叠内容
        .fold_container{
          padding: 0 10px 10px 10px;
          background-color: rgb(27,164,222);
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          position: absolute;
          top: 65px;
          left: 10px;
          right: 10px;
          z-index: 100;
          .add_up_duration{

          }
          .apply_info{

          }
          .notes{
            white-space: pre-line
          }
        }
      }
    }
  }
  .live_controller{
    //margin-top: 85px;
    .live_controller_player{
      border-radius: 4px;
      background-color: #F4F5F8;
      .cover_url{
        width: 100%;
        background-size: 100%,100%;
        background-repeat: no-repeat;
        position: relative;
        .time_countdown{
          padding: 2px;
          background-color: #F4F5F8;
          position: absolute;
          bottom: 10px;
          right: 10px;
          border-radius: 4px;
          color: rgb(29,29,31);
        }
      }
      .live_info{
        margin:0 3% 0 3%;
        height: 70px;
        .live_info_main{
          height: 50px;
          display: flex;
          flex-direction: column-reverse;
          .controller{
            display: flex;
            justify-content: space-between;
            .title{
              font-size: 24px;
              font-family: Adobe Heiti Std,serif;
              font-weight: 500;
              span{
                color: rgb(29,29,31);
                font-family: PingFang-SC,Helvetica,Microsoft Yahei,serif!important;
              }
            }
            .live_watch_number{
              font-size: 14px;
              font-family: Lantinghei SC,serif;
              font-weight: 400;
              color: #797979;
              display: flex;
              flex-direction: column-reverse;
              span{
                font-family: PingFang-SC,Helvetica,Microsoft Yahei,serif!important;
                .el-icon-share{
                  margin-left: 14px;
                }
                .n-icon{
                  vertical-align:text-top;
                }
              }
            }
          }
        }
      }
    }
    //互动
    .live_controller_interplay{
      background-color: #F4F5F8;
      border-radius: 4px;
      .container{
        .interplay_menu{
          height: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .menu_text{
            width: 100%;
            text-align: center;
            font-size: 19px;
            font-family: 微软雅黑;
            font-weight: 750;
          }
          #menu_text_1{
            color: #03A9F4;
          }
        }
        .el-divider{
          margin: 5px 0 5px 0 ;
        }
        .chat_list{
          overflow-y: scroll;
          margin:0 10px 10px 10px;

          .container{
            //左
            .left_main{
              display: flex;
              .el-avatar{

              }
              .information{
                flex: 1;
                margin-left: 5px;
                .message_name{
                  font-size: 14px;
                  font-family: 微软雅黑,serif;
                  font-weight: 800;
                  color: #03A9F4;
                  margin-left: 6px;
                }
                .el-card{
                  border: none;
                  width: 95%;
                  font-size: 14px;
                  font-family: 微软雅黑,serif;
                  font-weight: 800;
                  color: rgba(83, 83, 83, 0.69);
                  background-color: #F4F5F8;
                  .el-card__body {
                    padding: 5px;
                  }
                }
              }
            }
            //右
            .right_main{
              display: flex;
              .el-avatar{
                .el-icon-user-solid{
                  color: rgb(133, 144, 241);
                }
              }
              .information{
                flex: 1;
                margin-right: 5px;
                .message_name{
                  text-align: right;
                  font-size: 14px;
                  font-family: 微软雅黑,serif;
                  font-weight: 800;
                  color: #03A9F4;
                  margin-right: 6px;
                }
                .el-card{
                  text-align: right;
                  border: none;
                  background-color: #F4F5F8;
                  margin:6px 0 0 5%;
                  width: 95%;
                  font-size: 14px;
                  font-family: 微软雅黑,serif;
                  font-weight: 800;
                  color: rgba(83, 83, 83, 0.69);
                  .el-card__body {
                    padding: 5px;
                  }
                }
              }
            }
          }
        }
        ::-webkit-scrollbar {
          width: 0 !important;
        }
        ::-webkit-scrollbar {
          width: 0 !important;height: 0;
        }
        .el-input{
          margin-bottom: 10px;
          .el-button{
            display: flex;
            height: 32px;
            background-color: #03A9F4;
            color: #E5E5E5;
          }
        }
        .look_back{
          height: 100px;
          width: 100%;
          //margin-bottom: -32px;
          .container{
            .video_list{
              #video_list0{
                color: #1BA4DE;
              }
              .el-button{
                font-size: 15px;
                color: #393939;
              }
            }
          }
        }
      }
    }
  }


  //海报
  .live_more{
    margin-top: 15px;
    background-color: #F4F5F8;
    border-radius: 4px;
    .menu{
      margin-left: 3%;
      display: flex;
      height: 45px;
      align-items: center;
      .name{
        font-size: 19px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #000000;
      }
    }
    .line{
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
      .controller{
        height: 1px;
        width: 94%;
        background: hsla(0,0%,89.8%,.15);
      }
    }
    .poster{
      display: flex;
      justify-content: center;
      padding-bottom: 30px;
      img{
        height: 100%;
        width: 94%;
      }
    }
  }
  .live_foot{
    height: 30px;
  }


  // 登录弹窗

  .login_dialog{
    .container{
      width: 80%;
      margin-left: 10%;
      .phone{

      }
      .code{
        margin-top: 15px;
      }
    }
    .dialog-footer{
      .visitor_login{
        color: rgba(0, 0, 0, 0.34);
        cursor:pointer;
        &:hover{
          text-decoration: underline
        }
      }
    }
  }
}

</style>

