import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button, Select , Row, Col,Loading,Input,Avatar,Card,Dialog,Form,FormItem,Alert,Divider,Carousel,CarouselItem} from 'element-ui';

// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component(CollapseTransition.name, CollapseTransition)

Vue.config.productionTip = false

Vue.use(Button).use(Row).use(Col).use(Loading)
    .use(Input).use(Avatar).use(Card).use(Dialog)
    .use(FormItem).use(Form).use(Alert).use(Divider)
    .use(Carousel).use(CarouselItem)

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')




