// 封装axios
import axios from 'axios'
const ERR_OK = 0
const baseURL = '/'
const api_head = 'https://yunicu-api.yunicu.com'
const headers={
    'x-client-tag':'live_pc'
}

export function get(url,params){
//封装get方法
    return axios.get(api_head+url,{
        headers:headers,
        params:{
            params
        }
    }).then((res)=>{
        const serverData = res.data
        if(serverData.code === ERR_OK){
            return serverData
        }
    }).catch((e)=>{
        console.log(e);
    })
}

//封装put方法
export function put(url,params){
    return axios.put(api_head+url, {
            params
        },
        {headers:headers}
    ).then((res)=>{
        const serverData = res.data
        if(serverData.code === ERR_OK){
            return serverData
        }
    }).catch((e)=>{
        console.log(e);
    })
}

//封装post方法
export function post(url,params){
    return axios.post(api_head+url,
        params,
        {headers:headers}
    ).then((res)=>{
        const serverData = res.data
        // if(serverData.code === ERR_OK){
            return serverData
        // }
    }).catch((e)=>{
        console.log(e);
    })
}

//封装自定义headers的post
export function post_complex(url,params,complex_headers){
    return axios.post(api_head+url,
        params,
        {headers:complex_headers}
    ).then((res)=>{
        const serverData = res.data
        // if(serverData.code === ERR_OK){
        return serverData
        // }
    }).catch((e)=>{
        console.log(e);
    })
}
